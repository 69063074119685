const landRouter = {
  route: null,
  name: null,
  title: '土地管理',
  type: 'folder', // 类型: folder, tab, view
  icon: 'iconfont icon-tushuguanli',
  filePath: 'view/land/', // 文件路径
  order: 3,
  inNav: true,
  
  children: [
    {
      title: '土地台账',
      type: 'view',
      name: 'MassList',
      route: '/mass/list',
      filePath: 'view/land/mass-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['查看地块详情','查看地块情况','新增地块信息','删除地块信息'],
    },
    {
      title: '地块汇总',
      type: 'view',
      name: 'MassZl',
      route: '/mass/zl',
      filePath: 'view/land/mass-zl.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['查看地块详情','查看地块情况','新增地块信息','删除地块信息'],
    },
    {
      title: '地片汇总',
      type: 'view',
      name: 'PieceList',
      route: '/piece/list',
      filePath: 'view/land/piece-list.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['查看地块详情','查看地块情况','新增地块信息','删除地块信息'],
    },
    {
      title: '组汇总',
      type: 'view',
      name: 'MassZu',
      route: '/mass/zu',
      filePath: 'view/land/mass-zu.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['查看地块详情','查看地块情况','新增地块信息','删除地块信息'],
    },
    {
      title: '村汇总',
      type: 'view',
      name: 'MassCu',
      route: '/mass/cu',
      filePath: 'view/land/mass-cu.vue',
      inNav: true,
      icon: 'iconfont icon-tushuguanli',
      permission: ['查看地块详情','查看地块情况','新增地块信息','删除地块信息'],
    },
   /* {
      title: '地片管理',
      type: 'view',
      name: 'PieceList',
      route: '/piece/list',
      filePath: 'view/land/piece-list.vue',
      inNav: true,
      icon: 'iconfont icon-add',
      permission: ['查看地片信息'],
    },*/
  ],
}

export default landRouter
